<script setup lang="ts">
import queryEditorPicks from '~~/graphql/queries/EditorPicks/EditorPicks.query.graphql'
import queryInstitutionEntries from '~~/graphql/queries/Institutions/InstitutionEntries.query.graphql'
import queryDataEntries from '~~/graphql/queries/Resources/ResourcesEntries.query.graphql'
import type { IEntriesInterface, IEntryInterface } from '~~/types/Entries'

const { staticText } = useText()
const appConfig = useAppConfig()
const baseStorageUrl = appConfig.storage.url
const storageBucket = appConfig.storage.bucket

definePageMeta({
  layout: 'frontpage',
  layoutTransition: {
    appear: true,
    css: true,
    enterFromClass: 'opacity-0 blur-3xl',
    enterActiveClass: 'transition duration-300',
    leaveToClass: 'opacity-0 blur-3xl',
    leaveActiveClass: 'transition duration-300',
    mode: 'out-in',
  },
})

// get institutions
const { data: institutionData } = await useAsyncQuery<IEntriesInterface>(
  queryInstitutionEntries,
  {
    limit: 6,
    sort: 'random',
  },
)

const filteredInstitutions = computed(
  () => institutionData.value?.entries.data ?? [],
)

// get editor picks
const { data: editorData } = await useAsyncQuery<IEntryInterface>(
  queryEditorPicks,
  {},
)
const filteredEditor = computed(
  () => editorData.value?.entry.frontpage_entries ?? [],
)

// get resources
const { data: result } = await useAsyncQuery<IEntriesInterface>(
  queryDataEntries,
  {
    limit: 4,
    currentPage: 1,
    sort: 'date desc',
  },
)
const filteredData = computed(() => result.value?.entries ?? [])

const orderingColors = {
  space: '#2EA0AF',
  cohesion: '#F38C13',
  ideas: '#C71A77',
  nature: '#A2B81C',
  digital: '#434196',
  dossiers: '#9694E1',
}
const slideColor = ref('#9694E1')
useListen('slide:changed', (slide) => {
  slideColor.value = orderingColors[slide.color]
})

const createDynamicGradient = computed(() => {
  const angle = '51.47'
  return `linear-gradient(${angle}deg, #434196 40.85%, ${slideColor.value} 78.56%)`
})

defineOgImageComponent('OgImagePage', {
  title: 'CrossArea',
})
</script>

<template>
  <div>
    <div class="flex-no-wrap flex flex-col overflow-x-hidden">
      <section
        :style="{ backgroundImage: createDynamicGradient }"
        class="m-2 rounded-[1.25rem] lg:mx-5 lg:mt-6"
      >
        <HeaderNavigation is-frontpage />

        <div
          class="container mx-auto mt-10 grid grid-cols-4 gap-8 pb-[60px] md:mt-5 lg:grid-cols-12 lg:pb-25"
        >
          <h3
            v-motion="{
              initial: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
              },
            }"
            class="col-span-4 text-balance font-sans text-mobileH1 text-white lg:col-span-8 lg:col-start-3 lg:text-center lg:text-h1"
          >
            Your central hub for <br />global and area studies
          </h3>
          <p
            v-motion="{
              initial: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
              },
            }"
            class="col-span-4 text-balance text-center font-sans text-[2.0625rem] leading-[2.875rem] text-white lg:col-span-10 lg:col-start-2"
          >
            {{ staticText.frontpage.aboutSubtitle }}
          </p>
        </div>
        <div
          v-motion="{
            initial: {
              opacity: 0,
            },
            visible: {
              opacity: 1,
            },
          }"
          class="z-10 bg-dark/50 py-10 bg-blend-multiply lg:py-25"
        >
          <div
            class="container mx-auto grid grid-cols-4 gap-x-10 lg:grid-cols-12"
          >
            <div
              class="col-span-4 flex flex-wrap items-center justify-center gap-6 lg:col-span-10 lg:col-start-2 lg:gap-10"
            >
              <h3
                class="order-1 w-full text-center font-sans text-mobileH1 text-white lg:text-h1"
              >
                {{ staticText.frontpage.whatlooking }}
              </h3>
              <WidgetsAutocomplete
                class="order-2 w-full max-w-[470px]"
                filled="white"
              />
            </div>
          </div>
        </div>
        <div class="z-10 rounded-b-[1.25rem] pb-[60px] pt-[100px]">
          <div
            class="container mx-auto grid grid-cols-4 gap-x-10 lg:mb-20 lg:grid-cols-12"
          >
            <div
              class="col-span-4 flex flex-wrap items-center justify-center gap-6 lg:col-span-10 lg:col-start-2 lg:gap-20"
            >
              <h3
                class="w-full text-center font-sans text-mobileH1 text-white lg:text-h1"
              >
                {{ staticText.frontpage.highlights }}
              </h3>
            </div>
          </div>
          <SliderFrontpage :slides="filteredEditor" />
        </div>
      </section>
      <section id="about" class="gradient-transition py-[60px] lg:py-25">
        <div class="container mx-auto grid grid-cols-4 gap-8 lg:grid-cols-12">
          <div
            class="col-span-4 flex flex-col items-center text-black lg:col-span-5 lg:col-start-1"
          >
            <h3 class="mb-10 text-center text-mobileH1 lg:mb-16 lg:text-h1">
              CrossArea’s Knowledge Order
            </h3>
            <div class="flex gap-6">
              <div class="size-6 rounded-full bg-space lg:size-14" />
              <div class="size-6 rounded-full bg-ideas lg:size-14" />
              <div class="size-6 rounded-full bg-cohesion lg:size-14" />
              <div class="size-6 rounded-full bg-nature lg:size-14" />
            </div>
          </div>
          <div class="col-span-4 lg:col-span-7 lg:col-start-6">
            <p class="font-sans text-[1.125rem] leading-8">
              Our knowledge order is a compass guiding you through the vastness
              of global dynamics. We weave together Space (exploring the
              dynamics of physical and social territories), Cohesion (unraveling
              the social fabrics that unite and divide communities), Ideas
              (navigating through diverse thought and belief systems), and
              Nature (balancing human progress with ecological stewardship).
              Each of these categories offers a distinct perspective, unveiling
              new connections and insights about our complex world and the
              knowledge we have stored at CrossArea.
            </p>
          </div>
          <div
            class="col-span-4 flex justify-center lg:col-span-4 lg:col-start-6 lg:mt-10"
          >
            <GenericButton to="/ordering-knowledge">
              <template #icon>
                <SvgoArrowBack class="size-6 rotate-180 text-white" />
              </template>
              <template #default>
                {{ staticText.cta.explore }}
              </template>
            </GenericButton>
          </div>
        </div>
      </section>

      <section id="resources" class="bg-gray-25/20 py-[60px] lg:py-25">
        <div class="container mx-auto grid grid-cols-4 gap-10 lg:grid-cols-12">
          <h3
            class="col-span-4 text-center text-mobileH1 text-black lg:col-span-10 lg:col-start-2 lg:mb-10 lg:text-h1"
          >
            {{ staticText.sections.latestResources }}
          </h3>
          <div class="col-span-4 w-full sm:col-span-4 lg:col-span-12">
            <SliderSwiper
              :breakpoints="{
                320: {
                  slidesPerView: 1.25,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 2.25,
                },
                768: {
                  slidesPerView: 2.25,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }"
            >
              <swiper-slide
                v-for="(item, index) in filteredData.data"
                :key="item.id"
              >
                <LazyGenericContentCard
                  v-motion="{
                    initial: {
                      x: 100,
                      opacity: 0,
                    },
                    enter: {
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 250 * index,
                      },
                    },
                  }"
                  :heading-level="4"
                  :entry="item"
                  class="max-lg:bg-none col-span-4 sm:col-span-2 lg:col-span-3"
                />
              </swiper-slide>
            </SliderSwiper>
          </div>
          <div class="col-span-4 mx-auto mt-0 lg:col-span-12 lg:mt-10">
            <GenericButton to="/search">
              <template #icon>
                <SvgoArrowBack class="size-6 rotate-180 text-white" />
              </template>
              <template #default>
                {{ staticText.cta.viewAllData }}
              </template>
            </GenericButton>
          </div>
        </div>
      </section>

      <section id="institutions" class="bg-white py-[60px] lg:py-25">
        <div class="container mx-auto grid grid-cols-4 gap-10 lg:grid-cols-12">
          <h3
            class="col-span-4 text-center text-mobileH1 text-black lg:col-span-10 lg:col-start-2 lg:mb-10 lg:text-h1"
          >
            {{ staticText.sections.relatedInstitutions }}
          </h3>
          <ul
            class="col-span-4 flex flex-wrap items-start gap-x-10 lg:col-span-12"
          >
            <li
              v-for="i in filteredInstitutions"
              :key="i.id"
              class="w-1/4 lg:w-auto"
            >
              <NuxtLink :to="`/institutions/${i.slug}`" class="relative flex">
                <NuxtImg
                  :src="`${baseStorageUrl}/${storageBucket}/${i.data_image.path}`"
                  :width="i.data_image.width"
                  :height="i.data_image.height"
                  :alt="`Logo-${i.data_image.caption}`"
                  fit="cover"
                  loading="lazy"
                  placeholder
                  format="avif"
                  class="inset-0 object-contain grayscale transition-all duration-300 ease-in-out hover:grayscale-0 lg:h-[200px] lg:w-40"
                />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <LazyFooterNewsletter />
    <LazyFooterInstitutions />
  </div>
</template>

<style>
.gradient-transition {
  background: linear-gradient(
    51.47deg,
    rgba(217, 217, 217, 0.2),
    rgba(46, 160, 175, 0.2),
    rgba(217, 217, 217, 0.2),
    rgba(243, 140, 19, 0.2),
    rgba(217, 217, 217, 0.2),
    rgba(199, 26, 119, 0.2),
    rgba(217, 217, 217, 0.2),
    rgba(162, 184, 28, 0.2)
  );
  background-size: 1600% 1600%;

  -webkit-animation: gradient 60s ease infinite;
  -moz-animation: gradient 60s ease infinite;
  animation: gradient 60s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 99%;
  }
  50% {
    background-position: 100% 2%;
  }
  100% {
    background-position: 0% 99%;
  }
}
@-moz-keyframes gradient {
  0% {
    background-position: 0% 99%;
  }
  50% {
    background-position: 100% 2%;
  }
  100% {
    background-position: 0% 99%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 99%;
  }
  50% {
    background-position: 100% 2%;
  }
  100% {
    background-position: 0% 99%;
  }
}
</style>
