<script setup lang="ts">
//  refine, currentRefinement, and indices as props
import SearchIcon from 'assets/svgs/search.svg'
import MicIcon from 'assets/svgs/mic.svg'

const props = defineProps({
  refine: Function,
  currentRefinement: String,
  indices: Array,
  styling: Object,
  currentIndex: String,
})

const inputRef = ref(null)
const searchBarText = ref('')
const labels = ref([])
const latestLabelHighlighted = ref(false)
const query = ref('')

const searchFocus = ref(false)
const { staticText } = useText()

async function goToSearch(searchTerm: string) {
  await navigateTo({
    path: '/search',
    query: { 'data[query]': searchTerm },
  })
}

const baseUrl = computed(() => {
  switch (props.currentIndex) {
    case 'data':
      return '/data'
    case 'people':
      return '/scholars'
    case 'institutions':
      return '/institutions'
    case 'events':
      return '/events'
    case 'publications':
      return '/publications'
    case 'collections':
      return '/collections'
    case 'dossiers':
      return '/dossiers'
    default:
      return '/' // Default path if none matches
  }
})

function onSearchBarInput(event) {
  searchBarText.value = event.target.value
}

function removeLabel(index: number) {
  labels.value = labels.value.filter((_, i) => i !== index)
}

watch([searchBarText, labels, latestLabelHighlighted], () => {
  //
  const listener = (e) => {
    if ((e.key === 'Tab') && searchBarText.value.trim() !== '') {
      console.log('Tab pressed')
      labels.value.push(`"${searchBarText.value}"`)
      searchBarText.value = ''
    }

    if (e.key === 'Backspace' && searchBarText.value === '') {
      console.log('Backspace pressed')
      if (latestLabelHighlighted.value) {
        labels.value = labels.value.slice(0, -1)
        query.value = query.value.split(' ').slice(0, -1).join(' ')
        latestLabelHighlighted.value = false
      }
      else {
        latestLabelHighlighted.value = true
      }
    }
  }

  // Add keydown event listener to the input
  inputRef.value?.addEventListener('keydown', listener)

  return () => {
    inputRef.value?.removeEventListener('keydown', listener)
  }
}, { immediate: true })

watch([searchBarText, labels], () => {
  if (labels.value.length === 0) {
    query.value = searchBarText.value.trim()
  }
  else {
    query.value = `${labels.value.join(' ')} ${searchBarText.value.trim()}`
  }
})

watch(query, () => {
// refine here
  console.log('query', query.value)
  props.refine(query.value)
})
</script>

<template>
  <div>
    <div
      v-for="(label, i) in labels"
      :key="i"
      class="flex items-center rounded-md bg-white/30 px-2 dark:bg-black/30"
      :class="[
        latestLabelHighlighted && i === labels.length - 1
          ? 'bg-white/60 dark:bg-black/60'
          : '',
      ]"
      @click="removeLabel(i)"
    >
      Stored value:
      {{ label }}
    </div>
    <span class="text-white">{{ props.currentIndex }}</span>
    <div class="relative">
      <form
        action=""
        role="search"
        novalidate
        class="flex justify-between rounded-[16px] border border-dark/20"
        :class="{
          'bg-gray-25/50': props.styling.filled === 'grey',
          // eslint-disable-next-line vue/eqeqeq
          'bg-white': props.styling.filled == 'white',
          '': searchBarText,
        }"
        @submit.prevent="goToSearch(searchBarText)"
      >
        <button
          type="submit"
          :title="staticText.app.search"
          class="relative rounded-l-[16px] border-black transition-colors duration-300 ease-in-out after:absolute after:right-0 after:top-1/2 after:h-8 after:-translate-y-1/2 after:border-r peer-focus:bg-black"
          :class="{
            'bg-black text-white': searchFocus || props.styling.dark,
            'p-4': !props.styling.large,
            'px-4 py-6': props.styling.large,
            'bg-white text-black': !props.styling.dark,
          }"
        >
          <transition>
            <SearchIcon class="size-6" />
          </transition>
        </button>

        <input
          ref="inputRef"
          type="search"
          :value="searchBarText"
          placeholder="Search"
          class="grow appearance-none border-none bg-transparent pl-4 text-authors outline-none transition-colors duration-300 ease-in-out placeholder:text-dark/20 focus:ring-0 search-cancel:size-4 search-cancel:appearance-none search-cancel:bg-close-icon"
          :class="{
            'py-[12px]  ': !props.styling.large,
            'py-5': props.styling.large,
          }"
          @input="onSearchBarInput"
          @focus="searchFocus = !searchFocus"
          @blur="searchFocus = !searchFocus"
        >
        <div
          class="rounded-r-[16px] transition-colors duration-300 ease-in-out"
          :class="{
            'p-4 pr-2 ': !props.styling.large,
            'py-6 pl-4': props.styling.large,
          }"
        >
          <ais-voice-search
            class="flex size-6 items-center justify-center pr-2"
          >
            <template #buttonText="{ isListening }">
              <MicIcon
                :class="{ 'text-primary-200': isListening }"
                class="size-6"
              />
            </template>
          </ais-voice-search>
        </div>
      </form>
      <template v-if="searchBarText">
        <ul
          v-for="index in props.indices"
          :key="index.indexId"
          class="absolute z-[200] mt-2 w-full rounded-[20px] border border-transparent bg-white py-4 text-mobileBody text-dark shadow"
        >
          <li>
            <ul class="flex flex-col gap-3">
              <li
                v-for="hit in index.hits"
                :key="hit.objectID"
                class="relative mb-3 px-6 after:absolute after:inset-x-6 after:-bottom-3 after:h-px after:bg-gray-50 last:after:content-none hover:bg-gray-50"
              >
                <NuxtLink :to="`${baseUrl}/${hit.slug}`" class="block">
                  <ais-highlight attribute="title" :hit="hit" />
                </NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>
