<script setup lang="ts">
import { Splide, SplideTrack } from '@splidejs/vue-splide'
import type { PropType } from 'vue'
import type { Set_FrontpageEntries_Slide } from '~~/graphql/graphql'

const props = defineProps({
  slides: {
    type: Object as PropType<Set_FrontpageEntries_Slide[]>,
    required: true,
  },
})
const frontpageSlider = ref()
const frontpageSlides = ref()
const activeSlide = ref()
const slideTime = ref(0)

function onMove(splide, destIndex) {
  const { Layout, Move, Direction, Controller } = splide.Components
  const end = splide.Components.Controller.getEnd() + 1
  const rate = Math.min((splide.index + 1) / end, 1)
  activeSlide.value = destIndex
}

/* function onActive(splide) {
  const { Slides } = splide.Components
  useEvent('slide:changed', {
    number: activeSlide.value,
    color: Slides.getAt(activeSlide.value)?.slide.getAttribute('data-ordering'),
  })
} */

function onDragging(splide) {
  const { Layout, Move, Direction, Controller } = splide.Components
  const position = Direction.orient(Move.getPosition())
  const base = Layout.sliderSize() - Layout.listSize()
  const rate = position / base
  const adjustedRate = Math.min(rate, 1)
  // console.log(splide.Components.Elements.slides[1].offsetLeft); // 0-1
}

function onAutoplayPlaying(splide, rate) {
  // console.log(rate); // 0-1
  slideTime.value = rate
}

onMounted(() => {
  /*   if (frontpageSlider.value && frontpageSlider.value.splide) {
    console.log(frontpageSlider.value.splide.length)
  } */
})

// compute width of element with slideTime.value
const slideWidth = computed(() => {
  return `${slideTime.value * 100}%`
})
</script>

<template>
  <Splide
    ref="frontpageSlider"
    aria-labelledby="slider-title"
    class="relative"
    :has-track="false"
    :options="{
      autoplay: false,
      rewind: true,
      cover: false,
      lazyLoad: 'sequential',
      type: 'slide',
      drag: 'free',
      snap: true,
      perMove: 1,
      perPage: 2.5,
      gap: 40,
      pagination: false,
      arrows: true,
      classes: {
        active: 'bg-black',
      },
      breakpoints: {
        640: {
          perPage: 2,
        },
        1024: {
          perPage: 1.75,
        },
        1440: {
          perPage: 1.75,
        },
        1920: {
          perPage: 1.75,
        },
      },
    }"
    @splide:autoplay:playing="onAutoplayPlaying"
    @splide:dragging="onDragging"
    @splide:move="onMove"
    @splide:active="onActive"
  >
    <div class="ml-5">
      <SplideTrack>
        <SliderFrontpageSlide
          v-for="editor in props.slides"
          ref="frontpageSlides"
          :key="editor.id"
          :entry="editor"
        />
      </SplideTrack>
      <div class="splide__arrows splide__arrows--ltr">
        <button
          class="splide__arrow splide__arrow--prev absolute inset-y-0 left-0 z-[100]"
          type="button"
          aria-label="Previous slide"
          aria-controls="splide01-track"
        >
          <img
            src="/images/arrow_slider.png"
            alt="Previous slide"
            class="ml-5 mr-auto text-white"
          />
        </button>
        <button
          class="splide__arrow splide__arrow--next absolute inset-y-0 right-0 z-[100]"
          type="button"
          aria-label="Next slide"
          aria-controls="splide01-track"
        >
          <img
            src="/images/arrow_slider.png"
            alt="Next slide"
            class="ml-10 mr-auto rotate-180 text-white"
          />
        </button>
      </div>
      <div
        v-motion="{
          initial: {
            y: 20,
            opacity: 0,
          },
          visible: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 500,
            },
          },
        }"
        class="z-1 container absolute inset-x-0 bottom-5 mx-auto grid h-auto w-full grid-cols-4 items-end justify-end gap-10 lg:bottom-16 lg:grid-cols-12"
      >
        
      </div>
    </div>
  </Splide>
</template>

<style>
.splide__pagination__page.is-active {
  @apply opacity-100;
}
</style>
