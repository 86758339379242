

<script setup lang="ts">
import { useSearchStore } from '~~/stores/search'

const props = defineProps({
  large: {
    type: Boolean,
    default: false,
  },
  filled: {
    type: String,
    default: 'white',
  },
  dark: {
    type: Boolean,
    default: false,
  },
})

const indices = [
  'resources',
  'scholars',
  'institutions',
  'series',
  'insights',
  'collections',
]
const currentIndex = ref(indices[0])
const searchStore = useSearchStore()
const searchClient = useMeilisearchClient()
</script>

<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="currentIndex"
    :future="{
      preserveSharedStateOnUnmount: true,
    }"
  >
    <ais-configure :hits-per-page.camel="10" />

    <select v-model="currentIndex" class="form-select">
      <option v-for="indice in indices" :key="indice" :value="indice">
        {{ indice }}
      </option>
    </select>

    <ais-autocomplete>
      <template #default="{ currentRefinement: searchBarText, indices, refine }">
        <SearchLabels :current-refinement="searchBarText" :indices="indices" :refine="refine" :current-index="currentIndex" :styling="props" />
      </template>
    </ais-autocomplete>
  </ais-instant-search>
</template>
