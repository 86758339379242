<script setup lang="ts">
import { SplideSlide } from '@splidejs/vue-splide'
import type { SliderSlide } from '~~/types/Slider'
import type { PropType } from 'vue'

const props = defineProps({
  entry: {
    type: Object as PropType<SliderSlide>,
    required: true,
  },
})
const baseStorageUrl = useAppConfig().storage.url
const storageBucket = useAppConfig().storage.bucket
const { staticText } = useText()

/* const raColor = computed(() => {
  if (props.entry.linked_entry?.ordering_knowledge)
    return `text-${props.entry.linked_entry?.ordering_knowledge[0].short_title.toLowerCase()}`
  else return 'text-primary-400'
}) */

const computedLink = computed(() => {
  if (props.entry.linked_entry?.slug)
    return `/portal/${props.entry.linked_entry.slug}`
  return '#'
})
</script>

<template>
  <SplideSlide>
    <div
      class="relative grid h-auto overflow-hidden rounded-[20px] lg:h-[500px] lg:shadow-slider"
    >
      <div
        v-motion="{
          initial: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
          },
        }"
        class="inset-0 z-10 mx-auto w-full"
      >
        <div
          v-if="entry.slider_image"
          class="h-[300px] shadow-slider lg:h-[500px] lg:shadow-none"
        >
          <NuxtImg
            fit="cover"
            :height="entry.slider_image.height"
            :width="entry.slider_image.width"
            :src="`${baseStorageUrl}/${storageBucket}/${entry.slider_image.path}`"
            :alt="`Slider ${entry.linked_entry.short_title}`"
            sizes="sm:385px lg:1024px xl:1920px"
            densities="x1 x2"
            class="size-full object-cover"
            img-attrs="h-full w-full object-cover"
            format="avif"
            placeholder
          />
        </div>
      </div>
      <div
        class="z-10 flex items-end bg-dark/60 text-white backdrop-blur lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5"
      >
        <NuxtLink
          v-if="entry.linked_entry.short_title"
          class="mx-[50px] pb-8"
          :to="computedLink"
        >
          <h3 id="slider-title" class="text-mobileH3 lg:mb-0 lg:text-h3">
            {{ entry.linked_entry.short_title }}
          </h3>
        </NuxtLink>
      </div>
    </div>
  </SplideSlide>
</template>
